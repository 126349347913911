import type { Position as GeoPosition } from "@capacitor/geolocation";
import { navigate } from "package:/components/elements/Link";
import { DetailType, ROUTE_VIEW } from "package:/utils";
import type { AreaGeoResource } from "@greentrails/api";
import { type Map as MaptilerMap, Marker } from "@maptiler/sdk";
import { bearing, transformRotate } from "@turf/turf";
import type { Feature, FeatureCollection, LineString, Polygon, Position } from "geojson";

class MapGeoLayer {
  private map: MaptilerMap;
  private mapCanvas: HTMLElement;
  private areas: Partial<AreaGeoResource>[];
  private options = {
    interactive: true,
    positionMarker: false,
    pois: true,
    sectionMarker: false,
  };
  private lastHoveredId: number | undefined;
  private lastActiveId: number | undefined;
  private featureCollection: FeatureCollection;
  private featureCollectionConnections: FeatureCollection;
  private featureCollectionTrails: FeatureCollection;
  private featureCollectionRoutePoints: FeatureCollection;
  private featureCollectionPoiPoints: FeatureCollection;
  private featureCollectionSectionMarker: FeatureCollection;
  private featureCollectionPoiRescuePoints: FeatureCollection;
  private routeOutlineLayer;
  private routeTrailsLayer;
  private routeConnectionsLayer;
  private positionMarker;
  private userLocationMarker;
  private pointerType = "mouse";
  private baseRouteColor = "#003723";
  private baseActiveColor = "#8f64ed";
  private basePoiColor = "#ffffff";
  private minzoom = 12;

  constructor(
    map: MaptilerMap,
    mapCanvas: HTMLElement,
    areas: Partial<AreaGeoResource>[],
    options: {
      interactive?: boolean;
      positionMarker?: boolean;
      pois?: boolean;
      sectionMarker?: boolean;
    },
  ) {
    this.map = map;
    this.mapCanvas = mapCanvas;
    this.areas = areas;
    this.options = { ...this.options, ...options };
    this.minzoom = this.options.interactive ? this.minzoom : 0;
    this.featureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionTrails = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionConnections = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionRoutePoints = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionSectionMarker = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionPoiPoints = {
      type: "FeatureCollection",
      features: [],
    };
    this.featureCollectionPoiRescuePoints = {
      type: "FeatureCollection",
      features: [],
    };
    if (areas.length > 0) {
      this.initRoutes();
    }
  }

  private initRoutes() {
    this.featureCollection.features = [];
    this.featureCollectionTrails.features = [];
    this.featureCollectionConnections.features = [];
    this.featureCollectionRoutePoints.features = [];
    this.featureCollectionSectionMarker.features = [];
    this.featureCollectionPoiPoints.features = [];
    this.featureCollectionPoiRescuePoints.features = [];

    // start with index 2 to avoid issues checking for falsy values
    let index = 2;
    for (const area of this.areas) {
      if (area.rounds) {
        area.rounds.map((round) => {
          let coordinates: Position[] = [];
          if (round.trails) {
            round.trails.map((trail, trailIndex) => {
              const trailFeatureCollection =
                trail.geojson as unknown as FeatureCollection;
              trailFeatureCollection.features.map((feature) => {
                feature.id = index;
                feature.properties = feature.properties || {};
                feature.properties.area = area.slug;
                feature.properties.detail = round.slug;
                feature.properties.trailType = trail.type;
                feature.properties.type = DetailType.Round;
                feature.properties.color = round.color;
                const geometry = feature.geometry as GeoJSON.LineString;
                coordinates = [...coordinates, ...geometry.coordinates];
              });
              if (trail.type === "connection") {
                this.featureCollectionConnections.features.push(
                  ...trailFeatureCollection.features,
                );
              } else {
                this.featureCollectionTrails.features.push(
                  ...trailFeatureCollection.features,
                );
              }
              this.featureCollection.features.push(...trailFeatureCollection.features);

              // add section marker
              if (this.options.sectionMarker) {
                const coordinates = (
                  trailFeatureCollection.features[0]?.geometry as GeoJSON.LineString
                ).coordinates;
                let sectionMarkerPosition: Position | undefined;
                if (trailIndex === 0) {
                  sectionMarkerPosition = coordinates[0];
                  if (sectionMarkerPosition) {
                    this.addSectionMarker(sectionMarkerPosition, "A");
                  }
                }

                sectionMarkerPosition = coordinates[coordinates.length - 1];
                if (sectionMarkerPosition) {
                  this.addSectionMarker(
                    sectionMarkerPosition,
                    `${round.trails.length < 2 ? "B" : trailIndex + 1}`,
                  );
                }
              }
            });

            // add handles for rounds from api, fallback center point of round
            const handleCoordinates =
              round.handle_lat && round.handle_lng
                ? [round.handle_lng, round.handle_lat]
                : (coordinates[Math.floor(coordinates.length / 2)] as Position);

            this.featureCollectionRoutePoints.features.push({
              type: "Feature",
              id: index,
              geometry: {
                type: "Point",
                coordinates: handleCoordinates,
              },
              properties: {
                id: index,
                icon: "map-route-marker-light",
                iconLight: "map-route-marker-light",
                iconDark: "map-route-marker-dark",
                area: area.slug,
                detail: round.slug,
                color: round.color,
                iconSizeMultiplier: 1,
                type: DetailType.Round,
              },
            });

            index++;
          }
        });
      }

      if (area.trails) {
        area.trails.map((trail) => {
          if (trail.geojson) {
            const trailFeatureCollection = trail.geojson as unknown as FeatureCollection;
            trailFeatureCollection.features.map((feature) => {
              feature.id = index;
              feature.properties = feature.properties || {};
              feature.properties.area = area.slug;
              feature.properties.detail = trail.slug;
              feature.properties.type = DetailType.Trail;
              feature.properties.color = this.baseActiveColor;
            });
            if (trail.type === "connection") {
              this.featureCollectionConnections.features.push(
                ...trailFeatureCollection.features,
              );
            } else if (trail.type === "trail") {
              this.featureCollectionTrails.features.push(
                ...trailFeatureCollection.features,
              );
            }

            // add trail start and end marker
            if (this.options.sectionMarker) {
              const coordinates = (
                trailFeatureCollection.features[0]?.geometry as GeoJSON.LineString
              ).coordinates;
              const sectionMarkerStartPosition = coordinates[0];
              if (sectionMarkerStartPosition) {
                this.addSectionMarker(sectionMarkerStartPosition, "A");
              }
              const sectionMarkerEndPosition = coordinates[coordinates.length - 1];
              if (sectionMarkerEndPosition) {
                this.addSectionMarker(sectionMarkerEndPosition, "B");
              }
            }

            this.featureCollection.features.push(...trailFeatureCollection.features);
            index++;
          }
        });
      }

      if (area.pois) {
        for (const poi of area.pois) {
          // separate rescue points from other pois
          const featureCollection =
            poi.category.slug === "rettungspunkt"
              ? this.featureCollectionPoiRescuePoints
              : this.featureCollectionPoiPoints;
          featureCollection.features.push({
            type: "Feature",
            id: index,
            properties: {
              id: index,
              area: area.slug,
              detail: poi.slug,
              category: poi.category.slug,
              icon: `poi-${poi.category.icon}-dark`,
              iconLight: `poi-${poi.category.icon}-light`,
              iconDark: `poi-${poi.category.icon}-dark`,
              iconSizeMultiplier: 1,
              type: DetailType.Poi,
            },
            geometry: {
              type: "Point",
              coordinates: [poi.lng ?? 0, poi.lat ?? 0],
            },
          });
          index++;
        }
      }
    }

    this.destroy();
    this.drawBaseLayer();
    this.drawActiveLayer();

    if (this.options.pois) {
      this.drawPois();
    }
    if (this.options.interactive) {
      this.drawHoverLayer();
      this.drawInteractionLayer();

      this.mapCanvas.addEventListener("pointerdown", this.onPointerDown.bind(this));
      this.map.on("mouseenter", "route_points_layer", this.onMouseEnter.bind(this));
      this.map.on("mouseleave", "route_points_layer", this.onMouseLeave.bind(this));
      this.map.on("click", "route_points_layer", this.onClick.bind(this));
      this.map.on("mouseenter", "pois_circle_layer", this.onMouseEnter.bind(this));
      this.map.on("mouseleave", "pois_circle_layer", this.onMouseLeave.bind(this));
      this.map.on(
        "mouseenter",
        "pois_rescuepoints_circle_layer",
        this.onMouseEnter.bind(this),
      );
      this.map.on(
        "mouseleave",
        "pois_rescuepoints_circle_layer",
        this.onMouseLeave.bind(this),
      );
      this.map.on("click", "pois_circle_layer", this.onClick.bind(this));
      this.map.on("click", "pois_rescuepoints_circle_layer", this.onClick.bind(this));

      this.map.on("click", (e: Event) => {
        if (!e.defaultPrevented) {
          window.dispatchEvent(new CustomEvent("map.blur"));
        }
      });
    }

    this.drawDirectionArrowLayer();
    if (this.options.sectionMarker) {
      this.drawSectionMarker();
    }

    if (this.options.positionMarker) {
      this.drawPositionMarker();
    }
  }

  private getFeatureId(detailSlug, detailType): number {
    return [
      ...this.featureCollection.features,
      ...this.featureCollectionPoiPoints.features,
      ...this.featureCollectionPoiRescuePoints.features,
    ].find(
      (feature) =>
        feature.properties &&
        feature.properties.detail === detailSlug &&
        feature.properties.type === detailType,
    )?.id as number;
  }

  private setFeatureState(id?: number, state?: object) {
    for (const source of [
      "routes_source",
      "trails_source",
      "connections_source",
      "route_points_source",
      "pois_source",
      "pois_rescuepoints_source",
    ]) {
      if (this.map.getSource(source)) {
        this.map.setFeatureState(
          {
            source: source,
            id: id,
          },
          state,
        );
      }
    }
  }

  // set icon color and size based on hover and active state
  private updatePoiState(id: number, hover = false, active = true) {
    [
      ...this.featureCollectionPoiPoints.features,
      ...this.featureCollectionPoiRescuePoints.features,
    ].map((feature) => {
      if (feature.id === id && feature.properties) {
        feature.properties.icon =
          (hover || id === this.lastActiveId) && active
            ? feature.properties.iconLight
            : feature.properties.iconDark;
        feature.properties.iconSizeMultiplier =
          id === this.lastActiveId && active ? 1.2 : 1;

        for (const layer of [
          "pois_rescuepoints_circle_layer",
          "pois_rescuepoints_icon_layer",
        ]) {
          const currentLayer = this.map.getLayer(layer);
          if (currentLayer) {
            const currentMaxZoom = currentLayer.maxzoom;
            this.map.setLayerZoomRange(
              layer,
              feature.properties.category === "rettungspunkt" && active ? 12 : 12 + 4,
              currentMaxZoom,
            );
          }
        }
      }
    });
    let currentSource = this.map.getSource("pois_source");
    if (currentSource !== undefined) {
      currentSource.setData(this.featureCollectionPoiPoints);
    }

    currentSource = this.map.getSource("pois_rescuepoints_source");
    if (currentSource !== undefined) {
      currentSource.setData(this.featureCollectionPoiRescuePoints);
    }
  }

  // set icon color and size based on hover and active state
  private updateRouteState(id: number, hover = false, active = true) {
    this.featureCollectionRoutePoints.features.map((feature) => {
      if (feature.id === id && feature.properties) {
        feature.properties.icon =
          (hover || id === this.lastActiveId) && active
            ? feature.properties.iconDark
            : feature.properties.iconLight;
        feature.properties.iconSizeMultiplier =
          id === this.lastActiveId && active ? 1.2 : 1;
      }
    });
    if (this.map.getSource("route_points_source") !== undefined) {
      this.map
        .getSource("route_points_source")
        .setData(this.featureCollectionRoutePoints);
    }
  }

  private drawBaseLayer() {
    this.map.addSource("routes_source", {
      type: "geojson",
      data: this.featureCollection,
    });

    this.routeOutlineLayer = {
      id: "route_outline_layer",
      type: "line",
      source: "routes_source",
      minzoom: this.minzoom,
      layout: {},
      paint: {
        "line-width": 5,
        "line-color": [
          "match",
          ["get", "trailType"],
          "connection",
          // "#ffffff00",
          "#ffffff",
          "#ffffff",
        ],
      },
    };

    this.map.addLayer(this.routeOutlineLayer);

    this.map.addSource("trails_source", {
      type: "geojson",
      data: this.featureCollectionTrails,
    });

    this.routeTrailsLayer = {
      id: "route_trails_layer",
      type: "line",
      source: "trails_source",
      minzoom: this.minzoom,
      layout: {},
      paint: {
        "line-width": 1.7,
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["get", "color"],
          ["boolean", ["feature-state", "active"], false],
          ["get", "color"],
          this.baseRouteColor,
        ],
      },
    };
    this.map.addLayer(this.routeTrailsLayer);

    this.map.addSource("connections_source", {
      type: "geojson",
      data: this.featureCollectionConnections,
    });

    this.routeConnectionsLayer = {
      id: "route_connections_layer",
      type: "line",
      source: "connections_source",
      minzoom: this.minzoom,
      layout: {},
      paint: {
        "line-width": 1.7,
        "line-dasharray": [2, 2],
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["get", "color"],
          ["boolean", ["feature-state", "active"], false],
          ["get", "color"],
          this.baseRouteColor,
        ],
      },
    };
    this.map.addLayer(this.routeConnectionsLayer);
  }

  private drawPois() {
    this.map.addSource("pois_source", {
      type: "geojson",
      data: this.featureCollectionPoiPoints,
    });
    this.map.addSource("pois_rescuepoints_source", {
      type: "geojson",
      data: this.featureCollectionPoiRescuePoints,
    });

    ["pois_rescuepoints", "pois"].map((source) => {
      this.map.addLayer({
        id: `${source}_circle_layer`,
        type: "circle",
        source: `${source}_source`,
        minzoom: source === "pois_rescuepoints" ? 16 : this.minzoom,
        layout: {},
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            // zoomlevel
            12,
            // radius
            ["case", ["boolean", ["feature-state", "active"], false], 8, 6],
            // zoomlevel
            16,
            // radius
            ["case", ["boolean", ["feature-state", "active"], false], 16, 14],
          ],
          "circle-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            this.baseActiveColor,
            ["boolean", ["feature-state", "active"], false],
            this.baseActiveColor,
            this.basePoiColor,
          ],
        },
      });

      this.map.addLayer({
        id: `${source}_icon_layer`,
        type: "symbol",
        source: `${source}_source`,
        minzoom: source === "pois_rescuepoints" ? 16 : this.minzoom,
        layout: {
          "icon-image": "{icon}",
          "icon-allow-overlap": true,
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            // zoomlevel
            12,
            // textsize
            ["*", ["get", "iconSizeMultiplier"], 0.1],
            // zoomlevel
            16,
            // textsize
            ["*", ["get", "iconSizeMultiplier"], 0.25],
          ],
        },
        paint: {
          "icon-translate": [0, -0.5],
          // "icon-opacity": 0.5,
        },
      });
    });
  }

  private drawActiveLayer() {
    // layer on top for active state
    const routeOutlineActiveLayer = {
      ...this.routeOutlineLayer,
      id: "route_outline_active_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeOutlineLayer.paint,
        "line-width": 7,
      },
    };

    this.map.addLayer(routeOutlineActiveLayer);

    const routeTrailsActiveLayer = {
      ...this.routeTrailsLayer,
      id: "route_trails_active_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeTrailsLayer.paint,
        "line-width": 3.7,
      },
    };

    this.map.addLayer(routeTrailsActiveLayer);

    const routeConnectionsActiveLayer = {
      ...this.routeConnectionsLayer,
      id: "route_connections_active_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeConnectionsLayer.paint,
        "line-width": 3.7,
      },
    };

    this.map.addLayer(routeConnectionsActiveLayer);
  }

  private drawHoverLayer() {
    // create layers for hover state which are on top of the active state
    const routeOutlineHoverLayer = {
      ...this.routeOutlineLayer,
      id: "route_outline_hover_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeOutlineLayer.paint,
        "line-width": 7,
      },
    };

    this.map.addLayer(routeOutlineHoverLayer);

    const routeTrailsHoverLayer = {
      ...this.routeTrailsLayer,
      id: "route_trails_hover_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeTrailsLayer.paint,
        "line-width": 3.7,
      },
    };

    this.map.addLayer(routeTrailsHoverLayer);

    const routeConnectionsHoverLayer = {
      ...this.routeConnectionsLayer,
      id: "route_connections_hover_layer",
      filter: ["==", ["id"], ""],
      paint: {
        ...this.routeConnectionsLayer.paint,
        "line-width": 3.7,
      },
    };

    this.map.addLayer(routeConnectionsHoverLayer);

    // create route markers
    this.map.addSource("route_points_source", {
      type: "geojson",
      data: this.featureCollectionRoutePoints,
    });
  }

  private drawInteractionLayer() {
    this.map.addLayer({
      id: "route_points_circle_layer",
      type: "circle",
      source: "route_points_source",
      minzoom: this.minzoom,
      layout: {},
      paint: {
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // zoomlevel
          12,
          // radius
          ["case", ["boolean", ["feature-state", "active"], false], 8, 6],
          // zoomlevel
          16,
          // radius
          ["case", ["boolean", ["feature-state", "active"], false], 16, 14],
        ],
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          ["get", "color"],
          ["boolean", ["feature-state", "active"], false],
          ["get", "color"],
          this.baseRouteColor,
        ],
      },
    });

    this.map.addLayer({
      id: "route_points_layer",
      type: "symbol",
      source: "route_points_source",
      minzoom: this.minzoom,
      layout: {
        "icon-image": "{icon}",
        "icon-allow-overlap": true,
        // "icon-size": .5,
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // zoomlevel
          12,
          // textsize
          ["*", ["get", "iconSizeMultiplier"], 0.2],
          // zoomlevel
          16,
          // textsize
          ["*", ["get", "iconSizeMultiplier"], 0.5],
        ],
      },
      paint: {
        // "icon-opacity": 0.5,
      },
    });
  }

  private addSectionMarker(point: Position, label: string) {
    this.featureCollectionSectionMarker.features.push({
      type: "Feature",
      properties: {
        label: label,
      },
      geometry: {
        type: "Point",
        coordinates: [point[0] ?? 0, point[1] ?? 0],
      },
    });
  }

  private drawSectionMarker() {
    this.map.addSource("section_marker_source", {
      type: "geojson",
      data: this.featureCollectionSectionMarker,
    });

    this.map.addLayer({
      id: "section_marker_circle_layer",
      type: "circle",
      source: "section_marker_source",
      minzoom: this.minzoom,
      layout: {},
      paint: {
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          // zoomlevel
          12,
          // radius
          8,
          // zoomlevel
          16,
          // radius
          10,
        ],
        "circle-color": this.baseRouteColor,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });

    this.map.addLayer({
      id: "section_marker_text_layer",
      type: "symbol",
      source: "section_marker_source",
      minzoom: this.minzoom,
      layout: {
        "text-allow-overlap": true,
        "text-field": "{label}",
        "text-font": ["Roboto Condensed Bold"],
        "text-size": 10,
      },
      paint: {
        "text-color": "#ffffff",
      },
    });
  }

  private calculateArrowSize(zoom) {
    const zoom2 = 20;
    const size2 = 0.000005;
    const zoom1 = 13;
    const size1 = 0.0002;
    const a = (size2 - size1) / (zoom2 - zoom1);
    const b = size1 - a * zoom1;
    let triangleSize = a * zoom + b;
    triangleSize = Math.max(Math.min(triangleSize, size1), size2);
    return triangleSize;
  }

  private calculateArrowDistance(zoom) {
    const zoom2 = 20;
    const distance2 = 20;
    const zoom1 = 13;
    const distance1 = 150;
    const a = (distance2 - distance1) / (zoom2 - zoom1);
    const b = distance1 - a * zoom1;
    let distance = a * zoom + b;
    distance = Math.floor(Math.max(Math.min(distance, distance1), distance2));
    return distance;
  }

  private drawDirectionArrowLayer() {
    const zoom = this.map.getZoom();
    const triangleSize = this.calculateArrowSize(zoom);
    const arrowDistance = this.calculateArrowDistance(zoom);

    const trianglesGeoJSON: FeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    let index = 0;
    [
      ...this.featureCollectionTrails.features,
      ...this.featureCollectionConnections.features,
    ].map((feature) => {
      (feature?.geometry as LineString).coordinates.map((coordinate, coordinateIndex) => {
        index++;
        if (coordinate[0] && coordinate[1] && index % arrowDistance === 0) {
          const triangleCoordinates = [
            [coordinate[0], coordinate[1]],
            [coordinate[0] + triangleSize * 0.6, coordinate[1] - triangleSize],
            [coordinate[0] - triangleSize * 0.6, coordinate[1] - triangleSize],
            [coordinate[0], coordinate[1]],
          ];

          const triangleFeature: Feature<Polygon> = {
            type: "Feature",
            id: feature?.id,
            properties: {
              color: feature?.properties?.color,
            },
            geometry: {
              type: "Polygon",
              coordinates: [triangleCoordinates],
            },
          };
          // trianglesGeoJSON.features.push(triangleFeature);
          const options = {
            pivot: [coordinate[0], coordinate[1]],
            mutate: true,
          };
          const prevPoint = (feature?.geometry as LineString).coordinates[
            coordinateIndex - 3
          ];
          if (prevPoint) {
            const angle = bearing(
              [prevPoint[0], prevPoint[1]],
              [coordinate[0], coordinate[1]],
            );
            transformRotate(triangleFeature, angle, options);
            trianglesGeoJSON.features.push(triangleFeature);
          }
        }
      });
    });

    if (this.map.getSource("triangle_source") !== undefined) {
      this.map.getSource("triangle_source").setData(trianglesGeoJSON);
    } else {
      this.map.addSource("triangle_source", {
        type: "geojson",
        data: trianglesGeoJSON,
      });

      this.map.addLayer(
        {
          id: "triangle_fill_layer",
          minzoom: this.minzoom + 2,
          filter: ["==", ["id"], ""],
          type: "fill",
          source: "triangle_source",
          paint: {
            "fill-color": ["get", "color"],
          },
        },
        "route_connections_active_layer",
      );

      this.map.addLayer(
        {
          id: "triangle_outline_layer",
          minzoom: this.minzoom + 2,
          filter: ["==", ["id"], ""],
          type: "line",
          source: "triangle_source",
          layout: {},
          paint: {
            "line-color": "#ffffff",
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoomlevel
              12,
              // radius
              0.5,
              // zoomlevel
              16,
              // radius
              2,
            ],
          },
        },
        "route_connections_active_layer",
      );

      this.map.on("zoomend", this.onZoomEnd.bind(this));
    }
  }

  private onZoomEnd() {
    this.drawDirectionArrowLayer();
  }

  private drawPositionMarker() {
    const pulsingDot = document.createElement("div");
    pulsingDot.className = "position-marker";
    pulsingDot.innerHTML = "<span class='inner'></span>";

    // Add the pulsing dot as a marker on the map
    this.positionMarker = new Marker({ element: pulsingDot })
      .setLngLat([0, 0])
      .addTo(this.map);
  }

  private filterActiveLayer(id: string | number = "") {
    for (const layer of [
      "route_outline_active_layer",
      "route_trails_active_layer",
      "route_connections_active_layer",
      "triangle_outline_layer",
      "triangle_fill_layer",
    ]) {
      if (this.map.getLayer(layer)) {
        this.map.setFilter(layer, ["==", ["id"], id]);
      }
    }
  }

  //********** Event Handler **********//
  //***********************************//
  private onClick(event) {
    if (event?.features && event.features.length > 0) {
      this.map.setFilter("route_outline_hover_layer", ["==", ["id"], ""]);
      this.map.setFilter("route_trails_hover_layer", ["==", ["id"], ""]);
      this.map.setFilter("route_connections_hover_layer", ["==", ["id"], ""]);
      const detailSlug = event.features[0]?.properties?.detail;
      const detailType = event.features[0]?.properties?.type;
      const area = event.features[0]?.properties?.area;

      navigate({
        name: "lang-map-area-type-detail",
        params: {
          area: area,
          type: detailType,
          detail: detailSlug,
        },
        query: {
          view: ROUTE_VIEW.PREVIEW,
        },
        replace: true,
      });

      event.preventDefault();
    }
  }

  private onPointerDown(event) {
    this.pointerType = event.pointerType;
  }

  private onMouseEnter(event) {
    if (
      event?.features &&
      event.features.length > 0 &&
      this.pointerType === "mouse" &&
      event.features[0]?.id !== this.lastActiveId
    ) {
      this.lastHoveredId = event.features[0]?.id;
      this.updatePoiState(this.lastHoveredId ?? -1, true);
      this.updateRouteState(this.lastHoveredId ?? -1, true);
      this.map.setFilter("route_outline_hover_layer", ["==", ["id"], this.lastHoveredId]);
      this.map.setFilter("route_trails_hover_layer", ["==", ["id"], this.lastHoveredId]);
      this.map.setFilter("route_connections_hover_layer", [
        "==",
        ["id"],
        this.lastHoveredId,
      ]);
      // this.map.setFilter("triangle_fill_layer", ["==", ["id"], ""]);
      // this.map.setFilter("triangle_outline_layer", ["==", ["id"], ""]);
      this.setFeatureState(this.lastHoveredId, { hover: true });
    }
    this.mapCanvas.style.cursor = "pointer";
  }
  private onMouseLeave(event) {
    if (this.lastHoveredId !== undefined) {
      this.map.setFilter("route_outline_hover_layer", ["==", ["id"], ""]);
      this.map.setFilter("route_trails_hover_layer", ["==", ["id"], ""]);
      this.map.setFilter("route_connections_hover_layer", ["==", ["id"], ""]);
      // this.map.setFilter("triangle_fill_layer", ["==", ["id"], this.lastActiveId]);
      // this.map.setFilter("triangle_outline_layer", ["==", ["id"], this.lastActiveId]);
      // this.map.setFilter("route_trails_active", ["==", ["id"], this.lastActiveId]);
      this.updatePoiState(this.lastHoveredId, false);
      this.updateRouteState(this.lastHoveredId, false);
      this.setFeatureState(this.lastHoveredId, { hover: false });
      this.lastHoveredId = undefined;
    }
    this.mapCanvas.style.cursor = "";
  }

  //************* Public ***************//
  //***********************************//

  public setActiveRoute(detailSlug: string, detailType: string): void {
    this.resetActiveRoute();

    if (this.map.getSource("routes_source")) {
      if (this.lastActiveId !== undefined) {
        this.filterActiveLayer("");
        this.updatePoiState(this.lastActiveId);
        this.updateRouteState(this.lastActiveId);
        this.setFeatureState(this.lastActiveId, {
          active: false,
          hover: false,
        });
        this.lastActiveId = undefined;
      }

      this.lastActiveId = this.getFeatureId(detailSlug, detailType);
      if (this.lastActiveId !== undefined) {
        this.updatePoiState(this.lastActiveId);
        this.updateRouteState(this.lastActiveId);
        this.filterActiveLayer(this.lastActiveId);
        this.updatePoiState(this.lastActiveId);
        this.updateRouteState(this.lastActiveId);
        this.setFeatureState(this.lastActiveId, { active: true, hover: false });
      }
    }
  }

  public resetActiveRoute(): void {
    this.filterActiveLayer("");
    if (this.lastActiveId) {
      this.updatePoiState(this.lastActiveId, false, false);
      this.updateRouteState(this.lastActiveId, false, false);
      this.setFeatureState(this.lastActiveId, { active: false });
      this.lastActiveId = undefined;
    }
  }

  public setFilter(filter) {
    if (this.map.getLayer("pois_circle_layer")) {
      this.map.setFilter("pois_circle_layer", ["in", "category", ...filter]);
      this.map.setFilter("pois_icon_layer", ["in", "category", ...filter]);
    }
    if (this.map.getLayer("pois_rescuepoints_circle_layer")) {
      this.map.setFilter("pois_rescuepoints_circle_layer", ["in", "category", ...filter]);
      this.map.setFilter("pois_rescuepoints_icon_layer", ["in", "category", ...filter]);
    }
  }

  public updatePositionMarker(coordinate): void {
    if (coordinate.length > 1) {
      this.positionMarker.setLngLat(coordinate);
    }
  }

  public updateGeoData(areas: Partial<AreaGeoResource>[]) {
    this.areas = areas;
    this.initRoutes();
  }

  public updateUserLocationMarker(position: GeoPosition | null): void {
    if (position === null) {
      if (this.userLocationMarker) {
        // remove marker
        this.userLocationMarker.remove();
        this.userLocationMarker = undefined;
      }
      return;
    }

    if (!this.userLocationMarker) {
      // create marker
      const pulsingDot = document.createElement("div");
      pulsingDot.className = "user-location-marker";
      pulsingDot.innerHTML = "<span class='inner'></span>";
      this.userLocationMarker = new Marker({ element: pulsingDot })
        .setLngLat([position.coords.longitude, position.coords.latitude])
        .addTo(this.map);
    } else {
      this.userLocationMarker.setLngLat([
        position.coords.longitude,
        position.coords.latitude,
      ]);
    }
  }

  // setter for minzoom
  public setMinZoom(minzoom: number): void {
    if (this.minzoom === minzoom) return;

    this.minzoom = minzoom;
    for (const layer of [
      "route_outline_layer",
      "route_trails_layer",
      "route_connections_layer",
      "pois_circle_layer",
      "pois_icon_layer",
      "route_outline_active_layer",
      "route_trails_active_layer",
      "route_connections_active_layer",
      "route_outline_hover_layer",
      "route_trails_hover_layer",
      "route_connections_hover_layer",
      "route_points_circle_layer",
      "route_points_layer",
      "triangle_fill_layer",
      "triangle_outline_layer",
      "section_marker_circle_layer",
      "section_marker_text_layer",
    ]) {
      const currentLayer = this.map.getLayer(layer);
      if (currentLayer) {
        const currentMaxZoom = currentLayer.maxzoom;
        this.map.setLayerZoomRange(layer, minzoom, currentMaxZoom);
      }
    }
  }

  public destroy() {
    this.mapCanvas.removeEventListener("pointerdown", this.onPointerDown.bind(this));
    this.map.off("mouseenter", "route_points_layer", this.onMouseEnter.bind(this));
    this.map.off("mouseleave", "route_points_layer", this.onMouseLeave.bind(this));
    this.map.off("click", "route_points_layer", this.onClick.bind(this));
    this.map.off("mouseenter", "pois_circle_layer", this.onMouseEnter.bind(this));
    this.map.off("mouseleave", "pois_circle_layer", this.onMouseLeave.bind(this));
    this.map.off(
      "mouseenter",
      "pois_rescuepoints_circle_layer",
      this.onMouseEnter.bind(this),
    );
    this.map.off(
      "mouseleave",
      "pois_rescuepoints_circle_layer",
      this.onMouseLeave.bind(this),
    );
    this.map.off("click", "pois_circle_layer", this.onClick.bind(this));
    this.map.off("click", "pois_rescuepoints_circle_layer", this.onClick.bind(this));
    this.map.off("zoomend", this.onZoomEnd.bind(this));

    for (const layer of [
      "route_outline_layer",
      "route_trails_layer",
      "route_connections_layer",
      "pois_circle_layer",
      "pois_icon_layer",
      "pois_rescuepoints_circle_layer",
      "pois_rescuepoints_icon_layer",
      "route_outline_active_layer",
      "route_trails_active_layer",
      "route_connections_active_layer",
      "route_outline_hover_layer",
      "route_trails_hover_layer",
      "route_connections_hover_layer",
      "route_points_circle_layer",
      "route_points_layer",
      "triangle_fill_layer",
      "triangle_outline_layer",
      "section_marker_circle_layer",
      "section_marker_text_layer",
    ]) {
      if (this.map.getLayer(layer)) {
        this.map.removeLayer(layer);
      }
    }
    for (const source of [
      "routes_source",
      "trails_source",
      "connections_source",
      "route_points_source",
      "pois_source",
      "pois_rescuepoints_source",
      "triangle_source",
      "section_marker_source",
    ]) {
      if (this.map.getSource(source)) {
        this.map.removeSource(source);
      }
    }
  }
}

export default MapGeoLayer;
