import { icons } from "package:/components/elements/Icon";
import type { Map as MaptilerMap } from "@maptiler/sdk";

export function svgToImageElement(
  svg: string,
  width: number,
  height: number,
  onLoad,
): void {
  const img = new Image(width, height);
  const svgString = `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(svg)}`;
  img.onload = () => {
    onLoad(img);
  };
  img.src = svgString;
}

export function isValidBoundingBox(boundingBox: number[]): boolean {
  const [minLng, minLat, maxLng, maxLat] = boundingBox;
  if (minLng && minLat && maxLng && maxLat) {
    return (
      minLng <= maxLng &&
      minLat <= maxLat &&
      minLng >= -180 &&
      minLat >= -90 &&
      maxLng <= 180 &&
      maxLat <= 90
    );
  }
  return false;
}

export function loadMapIcons(map: MaptilerMap): void {
  const markerIconList = [
    "map-route-marker",
    "poi-restaurant",
    "poi-bike_rental",
    "poi-culture",
    "poi-nature",
    "poi-parking",
    "poi-rescue_point",
    "poi-toilet",
    "poi-workshop",
    "poi-misc",
  ];
  const variants = [
    { name: "light", color: "#fff" },
    { name: "dark", color: "#003723" },
  ];
  markerIconList.map((iconName) => {
    const icon = icons.get(iconName);
    const markerIcon = icon?.type === "svg" ? icon.text : null;
    if (markerIcon) {
      variants.map((variant) => {
        svgToImageElement(
          markerIcon.replaceAll("currentColor", variant.color),
          28 * 2,
          28 * 2,
          (img) => {
            map.addImage(`${iconName}-${variant.name}`, img);
          },
        );
      });
    }
  });
}
