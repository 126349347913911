import { ref, onUnmounted, onMounted } from "#imports";
import { Geolocation, type Position as GeoPosition } from "@capacitor/geolocation";

class UserGeoLocation extends EventTarget {
  private static watchId: string | null = null;
  public static lastUserPosition: GeoPosition | null = null;

  public watch(callback: (position: GeoPosition) => void) {
    this.requestGeolocationWatcher();
    const onPositionUpdate = (event: Event) => {
      // @ts-ignore
      callback(event.detail);
    };

    this.addEventListener("UserGeoLocation.update", onPositionUpdate);

    return () => {
      this.removeEventListener("UserGeoLocation.update", onPositionUpdate);
    };
  }

  private async requestGeolocationWatcher() {
    // clear watch if already exists
    if (UserGeoLocation.watchId !== null) {
      Geolocation.clearWatch({ id: UserGeoLocation.watchId });
      UserGeoLocation.watchId = null;
    }

    if (UserGeoLocation.watchId === null) {
      const permission = await Geolocation.checkPermissions();
      if (permission.location === "granted" || permission.location === "prompt") {
        UserGeoLocation.watchId = "";
        const watchId = await Geolocation.watchPosition(
          { enableHighAccuracy: true },
          (position) => {
            UserGeoLocation.lastUserPosition = position;
            this.dispatchEvent(
              new CustomEvent("UserGeoLocation.update", { detail: position }),
            );
          },
        );
        UserGeoLocation.watchId = watchId;
      }
    }
  }
}

let userGeoLocation: UserGeoLocation | null = null;
if (typeof window !== "undefined") {
  userGeoLocation = new UserGeoLocation();
}

export function useGeoLocation() {
  const lastUserPosition = ref(UserGeoLocation.lastUserPosition);
  
  const requestGeolocationWatcher = () => {
    return userGeoLocation?.watch((position) => {
      lastUserPosition.value = position;
    });
  };

  const exports = { lastUserPosition, requestGeolocation: requestGeolocationWatcher }
  if (!userGeoLocation) return exports;

  let unWatchGeoLocation: ReturnType<typeof userGeoLocation.watch> | null | undefined = null;


  onMounted(() => {
    unWatchGeoLocation = requestGeolocationWatcher();
  });

  onUnmounted(() => {
    if (unWatchGeoLocation) {
      unWatchGeoLocation();
    }
  });

  return exports;
}
